import React from 'react';

const WikisReport = ({data}) => {
    console.log(data)
    return (
        <div>
            WikisReport
        </div>
    );
};

export default WikisReport;
