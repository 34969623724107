import React from 'react';

const Team = () => {
    return (
        <div>
            Team
        </div>
    );
};

export default Team;
