import React from 'react';
import {Box, Input} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Iconify} from "../components";

const SearchHandler = ({handleSearch, ...rest}) => {
    const {t} = useTranslation();
    const dir = t("dir");
    return (
        <Box sx={{position: "relative", width: 190}} mb={4}>
            <Input placeholder={t("Search")} onChange={(e) => handleSearch(e.target.value)} {...rest} />
            <Iconify sx={{position: "absolute", right: dir && 0, left: !dir && 0}} icon={"arcticons:xiaoyuan-search"}/>
        </Box>
    );
};

export default SearchHandler;
