import React from 'react';

const Drive = () => {
    return (
        <div>
            Drive
        </div>
    );
};

export default Drive;
