import React, {useEffect,use} from 'react';
import {Box, Button, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Iconify} from "../../../../components";
import ReactMarkdown from "react-markdown"

const ShowArticle = ({setOpenTab, openTab, currentID, data}) => {

    const dispatch = useDispatch();
    const {id} = useParams();
    const {t} = useTranslation();

    const filterData = data?.filter((item) => item.id === openTab.id)


    return (<>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                {t("Details_Document")}
            </Typography>
            <Button
                onClick={() => setOpenTab((state) => ({...state, tab: 1}))}
                variant="contained"
                endIcon={<Iconify icon="eva:arrow-back-outline"/>}
            >
                {t("Back")}
            </Button>

        </Stack>
        <Stack direction="column" spacing={5}>
            {filterData?.map((item) => (
                <>
                    <Typography>{t("Title")} : {item.title}</Typography>
                    <Typography>{t("type")} : {item.type}</Typography>
                    {item?.type === "html" ? (
                        <div dangerouslySetInnerHTML={{__html: item.body}}/>
                    ) : <ReactMarkdown children={item.body} />}
                </>
            ))}
        </Stack>

    </>);
};

export default ShowArticle;
