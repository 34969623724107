// material
import {Box, Grid, Container, Typography} from '@mui/material';
// components
import Page from '../components/Page';
import {
    AppTasks,
    AppNewUsers,
    AppBugReports,
    AppItemOrders,
    AppNewsUpdate,
    AppWeeklySales,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppCurrentSubject,
    AppConversionRates
} from '../sections/@dashboard/app';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {profile} from "../redux/features/users/profileSlice";

// ----------------------------------------------------------------------

export default function DashboardApp() {
    const dispatch = useDispatch();
    const {isSuccess} = useSelector((state) => state.UpdateProfile)
    useEffect(() => {
        dispatch(profile())
    }, [isSuccess]);


    return (
        <Page title="Dashboard | KMS">
            <Container maxWidth="xl">
                <Box sx={{pb: 5}}>
                    <Typography variant="h4">Hi, Welcome back</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppWeeklySales/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppNewUsers/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppItemOrders/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppBugReports/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                        <AppWebsiteVisits/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentVisits/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppConversionRates/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentSubject/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppNewsUpdate/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppOrderTimeline/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppTrafficBySite/>
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppTasks/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
