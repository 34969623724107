export {default as AdminCreate} from "./admin/AdminCreate";
export {default as User} from "./User";
export {default as Drive} from "./Drive";
export {default as team} from "./team";
export {default as Profile} from "./Profile";
export {default as Notification} from "./notification";
export {default as UserWorkSpace} from "./admin/workspace/User";
export {default as WorkSpaceList} from "./admin/customer/index";
export {default as UserReport} from "./admin/customer/Report/UserReport";
export {default as WorkSpaceDetails} from "./admin/customer/workSpaceDetails";
export {default as GetDeparteman} from "./admin/Departeman/getDepartemanList";
export {default as DetailsDepartemanList} from "./admin/Departeman/detailsDepartemanList";
export {default as CreateDeparteman} from "./admin/Departeman/createDeparteman";
export {default as GetTicketList} from "./admin/Ticket/getTicketList";
export {default as ShowTicket} from "./admin/Ticket/ShowTicket";
