import Cookies from "js-cookie";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

//components
import {Http, Alert} from "../../../../helper";
import ResponseHandler from "../../../../helper/ResponseHandler";

// ********************* Article Index  FUNCTIONALITY*********************


export const getArticleList = createAsyncThunk(
    "GetArticleList/Article",
    async (id, {rejectWithValue}) => {
        const res = await Http(`admin/customers/templates/index/${id}`, {method: "get"});
        return ResponseHandler({res, index: true});
    }
);
const getArticleListSlice = createSlice({
    name: "GetArticleList",
    initialState: {
        data: {},
        loading: false,
    },
    reducers: {},
    extraReducers: (b) =>
        b
            .addCase(getArticleList.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getArticleList.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.loading = false;
            })
            .addCase(getArticleList.rejected, (state, action) => {
                state.data = null;
                state.loading = false;
            }),
});

export default getArticleListSlice.reducer;
