import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {
    Button, Chip,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";

import {LoadingCore, PaginationCore} from "../../../../core";
import {Http} from "../../../../helper";
import {getArticleList} from "../../../../redux/features/admin/workspace/geArticle";
import {ShowArticle} from "../index";
import * as moment from "jalali-moment";

const TABLE_HEAD = [
    {
        id: 'Title',
        label: 'Title',
        alignRight: false
    },
    {id: 'type', label: 'type', alignRight: false},
    {id: 'created_at', label: 'created_at', alignRight: false},
    {id: 'updated_at', label: 'updated_at', alignRight: false},
    {id: 'Action', label: 'Action', alignRight: false}
];

const Article = () => {
    const [page, setPage] = useState(1);
    const [articleList, setArticleList] = useState();
    const {data, loading} = useSelector((state) => state.getArticleListSlice);
    const [openTab, setOpenTab] = useState({tab: 1, id: null});
    const dispatch = useDispatch();
    const {id} = useParams();
    const {t} = useTranslation();
    const dir = t("dir") === "rtl";
    const perPage = 10;

    useEffect(() => {
        dispatch((getArticleList(id)))
    }, []);

    useEffect(() => {
        setArticleList(data)
    }, [data]);
    // Show Details Task
    const showDetails = (id) => {
        setOpenTab({tab: 2, id: id})
    }
    ////Handle Page Change
    const handlePageChange = async (event, value) => {
        setPage(value);
        const page = value;
        const res = await Http(`admin/customers/article/index/${id}?page=${page}&perPage=${perPage}`, {
            method: "get",
        });
        if (res.status === 200) {
            setArticleList(res?.data?.data);
        }
    };


    if (loading) return <LoadingCore loading={loading}/>
    return (<>
        {openTab.tab === 1 ? (<>
            <TableContainer sx={{minWidth: 800, border: "1px solid #eee"}}>
                <Table dir={"ltr"}>
                    <TableHead>
                        <TableRow sx={{
                            "& th ": {
                                borderBottom: "1px solid #dce0e4",
                            }
                        }}>
                            {TABLE_HEAD.map((cell, index) => (
                                <TableCell sx={{whiteSpace: "nowrap"}} align={dir ? "right" : "left"}
                                           key={cell.id + index}>
                                    {t(cell.label)}
                                </TableCell>))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {articleList?.data?.map((row, i) => {
                            const {
                                id,
                                title,
                                type,
                                created_at,
                                updated_at
                            } = row
                            return (<TableRow
                                sx={{
                                    "& td ": {
                                        borderBottom: "1px solid #dce0e4",
                                    },
                                }}
                                hover
                                key={id + i}
                                tabIndex={-1}
                                role="checkbox"
                            >

                                <TableCell align={dir ? "right" : "left"}>{title}</TableCell>
                                <TableCell align={dir ? "right" : "left"}>
                                    <Chip
                                        label={type}
                                        variant={"outlined"}
                                        color={"primary"}/>
                                </TableCell>
                                <TableCell
                                    align={dir ? "right" : "left"}>{moment(created_at, "YYYY-MM-DD HH:mm:ss").locale("fa").format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                <TableCell
                                    align={dir ? "right" : "left"}>{moment(updated_at, "YYYY-MM-DD HH:mm:ss").locale("fa").format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                <TableCell align={dir ? "right" : "left"}>
                                    <Button onClick={() => showDetails(id)} variant={"outlined"}
                                            color={"info"}>
                                        {t('Details')}
                                    </Button>
                                </TableCell>
                            </TableRow>);
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {articleList?.total >= 10 && (<PaginationCore>
                <Pagination
                    page={page}
                    dir={"ltr"}
                    onChange={handlePageChange}
                    count={Math.ceil(data?.total / data?.per_page)}
                    variant="outlined"
                    color="primary"
                    disabled={data?.total < 9}
                />
            </PaginationCore>)}
        </>) : (<ShowArticle data={articleList?.data} currentID={id} openTab={openTab} setOpenTab={setOpenTab}/>)}
    </>);
};

export default Article;
