import React from 'react';

const Customer = () => {
    return (
        <div>
            Customer
        </div>
    );
};

export default Customer;
